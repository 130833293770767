import React from "react"
import Header from "./header"
import Footer from "../components/common/footer"
import Features_mbl from "../components/common/features_mbl"
import { graphql } from "gatsby"
import { useTranslation, I18nextContext ,Link} from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Features = () => {
  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/features/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/features/`
  const canoncalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { t } = useTranslation()
  return (
    <div className="Layout">
      <Header />
      <Features_mbl />
      <Footer />
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `

<!-- Google Tag Manager -->

<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TK7M58Z');</script>

  `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `

<!-- Google Tag Manager -->

<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
  `,
          }}
        />
        <meta charSet="utf-8" />
        <title>{t("Features - Logipusle Shipping Software Solutions")}</title>

        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("Right from multilingual interfaces to Warehouse 2D view, Logipulse solutions are integrated with various unique features to handle your supply chain instantly. Invest in Logipulse software solutions and simplify your logistics processes.")}
        />
        <meta
          name="Keywords"
          content={t("Logipulse software features")}        
        />
      </Helmet>
    </div>
  )
}

export default Features

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
