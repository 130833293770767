import React, { useState, useEffect } from "react";
import "../../styles/features_mbl.scss";
import Photo from "../../components/Photo";
import { Trans, I18nextContext } from 'gatsby-plugin-react-i18next';
import Featuresdetails from './featuresdetials';
import { graphql } from "gatsby";



const Features_mbl = (props, { children }) => {
  const context = React.useContext(I18nextContext);
  const [id, setElementId] = useState("multilingual");
  const [isToggle, setToggleValue] = useState(true)

  const operations = (newElementId) => {

    if (id != newElementId) {
      setElementId(newElementId);
      setToggleValue(true);
    } else {
      setElementId(newElementId);
      setToggleValue(!isToggle);
    }
  }


  const focusMethods = (name) => {
  }

  useEffect(() => {
    let currenturl = window.location.href;
    let currentpage = currenturl.substring(currenturl.lastIndexOf('/') + 1);
    let ElementId = currentpage.replace("#", "");
    setElementId(ElementId);

  }, [])

  return (
    <div className="layout">
      <main>{children}</main>
      <Featuresdetails />
      <div className="pricings-content mble_view_features" lang={context.language}>
        <div className="container">
          <div className="row">

            <div className="col-md-4 columnstyle ">
              <div className="features-contents" id="features-contents" lang={context.language}>
                <div className="materialssection">

                  <div className="alingupside">


                    <div className="sample" id="sample0" onClick={() => focusMethods("multilingual")} lang={context.language}>

                      <div onClick={() => operations("multilingual")} id="multilingual" className="spacecontent" lang={context.language}>
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Multilingual Interface</Trans>
                      </div>

                      {id === "multilingual" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Multi Lanuage.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="multilingual" lang={context.language}>
                              {" "}
                              <Trans>Multilingual Interface</Trans>
                            </div>
                          </div>

                          <div className="Multi_first_maincontent" lang={context.language}>
                            <Trans>As the freight forwarding process involves many locations in single freight itself, it is necessary for you to have a global user interface with multiple languages. English is the globally accepted language and most of the time it is the language that connects people internationally, but your native local language is also equally important when it comes to the logistics business. You can switch between any language within a click. whole application and data will be converted to your desired language instantly. Logipulse is enabled with multiple languages to break the barrier of boundaries.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}

                    </div>

                  </div>
                </div>



                <div className="materialssection">
                  <div
                    onClick={() => operations("multicurrency")} id="multicurrency"
                    className="alingupside">


                    <div className="sample" id="sample1" onClick={() => focusMethods("multicurrency")}>
                      <div className="idsample" lang={context.language}>

                        <div className="spacecontent">
                          <div className="rightup" lang={context.language}>
                            <Photo
                              src="KnowMore-Right Arrow-H12.svg"
                              className="testing"
                              alt=""
                            />
                          </div>
                          <Trans>Multi-Currency</Trans>
                        </div>
                      </div>


                      {id === "multicurrency" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Muli Branch Divisions.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Multi-Currency</Trans>
                            </div>
                          </div>

                          <div className="Multi-Branch_first1_maincontent" lang={context.language}>
                            <Trans>Spread out your wings without the worry of multicurrency transactions. With the application, you will be able to do exchanges, payment settlements, bill and invoice generation, and more with whichever currency you enter. Online rate management is pretty easy with Logipulse. You don't have to worry about the exchange rates, the application will handle it by updating automatically. A 3-level currency management facility is made available for you. If you start your operation with one currency and your customer is in a different location with a different currency and the final payment is done in a different location with a different currency, you can still do the finances without the hassle of exchange rate issues.</Trans>
                          </div>

                          <br></br>
                        </div>
                      ) : null}



                    </div>
                  </div>
                </div>



                <div className="materialssection">
                  <div
                    onClick={() => operations("Quotes")} id="Quotes"
                    className="alingupside">


                    <div className="sample" id="sample1" onClick={() => focusMethods("Quotes")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Quotes</Trans>
                        {/* <br /><Trans>with agnecies</Trans>{" "} */}
                      </div>
                      {id === "Quotes" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Track and Trace.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Quotes</Trans>
                            </div>
                          </div>

                          <div className="Track_maincontent" lang={context.language}>
                          <Trans>The Logipulse quotation module lets you create and send professional digital quotes to your customers instantly. Quotations can be created for walk-in and regular customer inquiries and include all contract rates from carriers. You can convert a CRM sales lead into a quote efficiently. Also, supervise all your existing and pending quote approvals from a single window quickly.</Trans>
                            <br></br><br></br>
                            <Trans>You can email your customers and receive reports immediately. With Logipulse, you can email freight quotes to your customers directly from the application. Provide a detailed breakdown of the quote highlighting key commodities so your customers can easily examine the quote's aspects. This will help your customers receive in-depth information about their orders.</Trans>
                            <br></br><br></br>
                            <Trans>Logipulse allows you to maintain different freight quotes for an enquiry. You can easily change key metrics in the quote and send the best and accurate quotes to your customers.</Trans>
                            <br></br><br></br>
                            <Trans>Notifications will be generated to concerned officers to let them know the status of the quotes. If you are an authorized person or manager, you can approve quotes. Your quotation approval will generate shipment or pickup formalities with just a click.</Trans>
                            <br></br><br></br>
                            <Trans>Reports of the quotations will be generated to know the success rate. KPI reports will be available from which you can see the performance and status of the leads you assigned to your employees.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}



                    </div>
                  </div>
                </div>




                <div className="materialssection">
                  <div
                    onClick={() => operations("Jobs")} id="Jobs"
                    className="alingupside"
                  >

                    <div className="sample" id="sample3" onClick={() => focusMethods("Jobs")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div><Trans>Jobs</Trans></div>

                      {id === "Jobs" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image ">
                              <Photo
                                src="Dashboard with KPI.jpg"

                                alt=""
                              />
                            </div>

                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Jobs</Trans>
                            </div>
                          </div>

                          <div className="Dashboards_first_maincontent" lang={context.language}>
                          <Trans>With this module, you can create and schedule jobs quickly. You get a job module synchronized with the finance module and 3PL module. You can schedule or reschedule the jobs in three modes of transportation. A custom house can be created. Also, have the advantage of getting all the related documents instantly, house waybill, bill of lading, air waybill, truck waybill, multi-currency details, packing item details, types of transportation, etc. You can keep internal notes, job info, and other records while creating or scheduling the job. You can directly store shipments of jobs in a warehouse or deliver them directly.</Trans>
                             <br></br><br></br>
                          <Trans>The manifest section, being a dedicated one in the module, helps you handle it without any difficulty. Shipment and shipment items can be selected, and you can compose the manifest easily. Once the job is approved, a manifest can be created along with other required details you need for the process of loading. The premanifest feature helps you in the planning by adding or removing the items in the shipment. You need not have to send the documents physically to your destination agent; they can directly access the data online. Manifest sharing is made easier with Excel extract.</Trans>
                             <br></br><br></br>
                          <Trans>The manifest document can be downloaded at the destination.decisions accordingly. KPIs and BIs render actionable information because they are always measurable and quantifiable. It’s usually hard to keep all branches or participants within an organization regulated and pulling toward a common goal.</Trans>
                          <br></br><br></br>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("Transportation")} id="Transportation"
                    className="alingupside"
                  >

                    <div className="sample" id="sample4" onClick={() => focusMethods("Transportation")}>

                      <div className="colorname  spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Transportation</Trans></div>

                      {id === "Transportation" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Workflow Approvals.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Transportation</Trans>
                            </div>
                          </div>

                          <div className="Workflow_first1_maincontent" lang={context.language}>
                            <Trans>Logipulse transportation gives visibility into your everyday transportation operations, compliance information, and documentation, and maintains a well-timed delivery of freight and goods. We help you streamline the shipping process and make it easier for you to manage and optimize all your transportation operations, whether they are land, air, or ocean.</Trans>
                            <br></br> <br></br>
                            <Trans>Never lose track of your shipment transportation. Get the advantage of live shipment tracking, status updates, and more. Be it the main transportation, pre-transportation, or post-transportation operation, handle it digitally. You can handle multiple vehicles in one transport. In air transport, you will get the information of the flight number, date, and time, etc. In ocean transport, you can track the vessel. In land transport get the information and updates of your driver and route. Get or set pre-alerts and notifications including the information of the items in the package, documents, arrival notice, etc. and the best part is the advantage of the bill of lading through blockchain technology.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>



                <div className="materialssection">
                  <div
                    onClick={() => operations("DataSharing", 5, "sample5")}
                    className="alingupside"
                  >

                    <div className="sample" id="sample5" onClick={() => focusMethods("DataSharing")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Data Sharing with Agencies and Branches</Trans></div>

                      {id === "DataSharing" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Multi Currency.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Data Sharing with Agencies and Branches</Trans>
                            </div>
                          </div>

                          <div className="Currencys_first1_maincontent" lang={context.language}>
                            <Trans>With Logipulse you are getting a centralized data storage that is accessible to you, your agencies, and the branches in real-time without a local server, backup facility, or security system. Every hassle of these kinds of technical aspects, for instance, network, firewall, connectivity, backup, etc. will be managed by Logipulse for you. Every modification done will be reflected immediately in real-time with the Audit trail feature. You don't have to worry about files getting lost or sending every document physically with your associates.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("crossplatform")} id="crossplatform"
                    className="alingupside"
                  >

                    <div className="sample" id="sample6" onClick={() => focusMethods("crossplatform")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div><Trans>Track and Trace</Trans></div>

                      {id === "crossplatform" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Cross-Platform.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Track and Trace</Trans>
                            </div>
                          </div>

                          <div className="Cross_first_maincontents" lang={context.language}>
                            <Trans>You will get better visibility into your business while you render your customers a facility to track and trace their products and services from you. They can track their shipment that is going through different places/countries, you will not have to let them know the details every now and then. You, as well as your regular shipper/consignee, will get visibility into the operations, finances, shipment journey, finances, statuses, etc. Regular customers get an advantage of the user portal, by using the login credentials they can enter the portal and track their shipments or services.</Trans>

                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("sample7")} id="sample7"
                    className="alingupside"
                  >


                    <div className="sample" id="sample7" onClick={() => focusMethods("sample7")}>

                      <div className="spacecontent"> <div className="rightup" lang={context.language}>
                        <Photo
                          src="KnowMore-Right Arrow-H12.svg"
                          className="testing"
                          alt=""
                        />
                      </div><Trans>Dashboard with KPI's and BI's</Trans></div>

                      {id === "sample7" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Custom reports.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Dashboard with KPI's and BI's</Trans>
                            </div>
                          </div>

                          <div className="Custom_first_maincontent" lang={context.language}>
                            <Trans>KPI and BI reporting will give analytical insight into your business performance. Higher-level managers or owners can observe and access key results in an instant and take strategic decisions accordingly. KPIs and BIs render actionable information because they are always measurable and quantifiable. It’s usually hard to keep all branches or participants within an organization regulated and pulling toward a common goal.</Trans> 
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("multipletab")} id="multipletab"
                    className="alingupside"
                  >

                    <div className="sample" id="sample8" onClick={() => focusMethods("multipletab")}>

                      <div className="spacecontent"> <div className="rightup" lang={context.language}>
                        <Photo
                          src="KnowMore-Right Arrow-H12.svg"
                          className="testing"
                          alt=""
                        />
                      </div><Trans>Workflow and Approvals</Trans></div>

                      {id === "multipletab" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Multi tabs.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Workflow and Approvals</Trans>
                            </div>
                          </div>

                          <div className="Tabs_first1_maincontent" lang={context.language}>
                            <Trans>From organization to organization business workflow have difference. So rather than depending on a structured workflow Logipulse lets you configure your workflow as per your requirements and wish. In the workflow, you can configure the notification to be sent to the concerned persons in each department.</Trans>
                          </div>

                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("permissions")} id="permissions"
                    className="alingupside"
                  >

                    <div className="sample" id="sample9" onClick={() => focusMethods("permissions")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Cross-Platform User Interface</Trans></div>

                      {id === "permissions" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Permissions and Proval.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Cross-Platform User Interface</Trans>
                            </div>
                          </div>

                          <div className="Permissions_first1_maincontent" lang={context.language}>
                            <Trans>Logipulse logistics management software can be used in different modes or from different devices or different operating systems, for instance, Linux, IOS, or Windows. Logipulse can be accessed through browsers or can be used as an app on devices like phones or PDAs and as desktop applications. This feature enables you to make use of any available devices to work and also to multitask. Logistics being a very complex and happening process, a cross-platform user interface will surely benefit you.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("customreport")} id="customreport"
                    className="alingupside"
                  >

                    <div className="sample" id="sample10" onClick={() => focusMethods("customreport")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Custom Report Engine</Trans></div>

                      {id === "customreport" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Hierarchy Maintanance.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Custom Report Engine</Trans>
                            </div>
                          </div>

                          <div className="Hierarchy_first_maincontent" lang={context.language}>
                            <Trans>The entire report can be compiled as per your requirement. The intuitive interface of Logipulse will help you to compose and publish your report instantly. You don't have to contact the service provider, instead, the report can be customized as per your wish and publish on the system without any hassle. It is possible to customize reports easily with a basic knowledge of HTML or by reading the help document.</Trans>
                          </div>

                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("Alert")} id="Alert"
                    className="alingupside"
                  >

                    <div className="sample" id="sample11" onClick={() => focusMethods("Alert")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Multiple Tab Viewer</Trans></div>

                      {id === "Alert" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Alert Engine.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Multiple Tab Viewer</Trans>
                            </div>
                          </div>

                          <div className="Alerts_first1_maincontent" lang={context.language}>
                            <Trans>Multitask, improve your work speed and data feeding with the multiple tab view feature. You can simultaneously handle several activities in different operations effortlessly. You can easily navigate from one tab to another to collect information. Studies report an increase in productivity and reduction in errors and functionality while using the multiple tab viewer feature. Moreover, data sharing between different operations become easier.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>



                <div className="materialssection">
                  <div
                    onClick={() => operations("Taskapproval")} id="Taskapproval"
                    className="alingupside"
                  >

                    <div className="sample" id="sample13" onClick={() => focusMethods("Taskapproval")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Task Approvals and Permissions</Trans></div>

                      {id === "Taskapproval" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Audit Traisl.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Task Approvals and Permissions</Trans>
                            </div>
                          </div>

                          <div className="Audit_first_maincontent" lang={context.language}>
                            <Trans>Nothing goes without the knowledge and approval of the authorized person you assigned. Every small modification made in the business or organization will be notified for approvals and permissions. The subsequent action will take effect only after it. Logipulse can handle approvals, permissions, and climb-up messages in case a person misses a notification.</Trans>
                            <br></br>
                            <Trans>The permission module is very advanced where different activities can be controlled with permission that can be user-level or department-level. Permission can be utilized at that time when data sharing happens between branches.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("Statetracking")} id="Statetracking"
                    className="alingupside"
                  >

                    <div className="sample" id="sample14" onClick={() => focusMethods("Statetracking")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Hierarchy Maintenance</Trans></div>

                      {id === "Statetracking" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents">
                            <div className="Image">
                              <Photo
                                src="State Tracking.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Hierarchy Maintenance</Trans>
                            </div>
                          </div>

                          <div className="State_first_maincontent" lang={context.language}>
                            <Trans>Create your own hierarchy for your organization and set alerts, notifications, and climb-up messages to receive. Department-wise messages can be broadcast easily according to the hierarchy you define. Hierarchy also helps in workflow management, permissions, and approvals.</Trans>
                            <br></br>
                            <Trans>Logipulse provides you the facility to optimize direct shipments (FTL) and consolidation (LTL) within a short duration, by utilizing the space effectively and cost-efficiently with the technology of advanced algorithms. You can include as many houses as possible in consolidated shipments and generate bills for that easily.</Trans>
                          </div>

                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">

                  <div
                    onClick={() => operations("Alertengine")} id="Alertengine"
                    className="alingupside"
                  >

                    <div className="sample" id="sample15" onClick={() => focusMethods("Alertengine")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Alert Engine</Trans></div>

                      {id === "Alertengine" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Accounting Solution.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Alert Engine</Trans>
                            </div>
                          </div>

                          <div className="Accounting_first11_maincontent" lang={context.language}>
                            <Trans>Set alerts at any desired point of the workflow or shipment. Alerts will be issued when the task assigned is delayed or missed so that the concerned people get notified to avoid further setbacks in the whole process. You can subscribe to any number of logistics events from mobile or desktop and at the time of that event, you will be notified. Notifications will be received as SMS or emails when the shipment process gets delayed or stalled, for whatever reason. Emails can be sent within the agency or to different branches, shippers, consignees or other actors, when exceptions happen, or if there is some information that needs to be alerted.</Trans>
   

                           

                          </div>

                          <div className="Accounting_second12_maincontent" lang={context.language}>
                            <Trans>Climb-up notifications can be set according to the employee hierarchy when certain works assigned are delayed. Information will be transferred to a higher-level employee in the hierarchy to act. For instance, if a task is not happening for a specified number of days, a message will be passed to an employee who is in a higher position, if he fails to act within a period the message will be transferred to an employee in a higher level than that of the previous employee, thus resolving the issue. Internal emails can be composed within the organization also.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>



                <div className="materialssection">
                  {/* <ico.ArrowForward /> */}
                  <div
                    onClick={() => operations("AutomatedDocuments")} id="AutomatedDocuments"
                    className="alingupside"
                  >

                    <div className="sample" id="sample17" onClick={() => focusMethods("AutomatedDocuments")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Automated Documents Generation</Trans></div>

                      {id === "AutomatedDocuments" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="CRM and Qutation.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Automated Documents Generation</Trans>
                            </div>
                          </div>

                          <div className="CRM_first1_maincontent" lang={context.language}>
                            <Trans>Logistics operations include a lot of documents and paperwork that until now was done manually. By automating all the documents issuing and receiving and archiving to a centralized server half of the task is considered done. Warehouse receipt issuing, Put-away and Pick-list documents, Proof of Delivery, invoices, manifest slips/sheets, content lists, arrival acknowledgment forms/reports, damaged goods, returned goods, summary reports, import/export, delivery, Bill of Lading (BOL), etc. can all be generated easily in Logipulse.</Trans>
                            <br></br><br></br>
                            <Trans>Different versions of documents can also be produced. Documents can be shared between agencies and can be emailed to desired people. Editable templates are available for you to create documents instantly. There are numerous documents required from pickup to delivery, automating and customizing it according to your need will make the process professional, accurate and fast.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("Customerportal")} id="Customerportal"
                    className="alingupside"
                  >

                    <div className="sample" id="sample18" onClick={() => focusMethods("Customerportal")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Audit Trail</Trans></div>
                      {id === "Customerportal" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Customer Poratal.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Audit Trail</Trans>
                            </div>
                          </div>

                          <div className="Customer_first1_maincontent" lang={context.language}>
                            <Trans>Different people from different places will be modifying the same document so each modification can be traced with the audit trail. Anyone can edit the data as it is centralized and accessible to people involved in your business. The log can be traced. It doesn’t matter if you overlooked important data when you were busy. The audit trail feature lets you check everything back and forth. An audit trail is a step-by-step report by which accounting, or operations data can be traced from its beginning. Edits done at any time in the past can be retrieved. Audit trails are used to verify and trace many types of transactions including accounting and operational activities.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("IntegratedState")} id="IntegratedState"
                    className="alingupside"
                  >

                    <div className="sample" id="sample19" onClick={() => focusMethods("IntegratedState")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Integrated State Updates</Trans></div>
                      {id === "IntegratedState" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="User Portal.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Integrated State Updates</Trans>
                            </div>
                          </div>

                          <div className="Portals_first_maincontent" lang={context.language}>
                            <Trans>Logipulse provides you with a dedicated and most intelligent state engine that can track all the entities in your logistics business. It gives you separate updates, for instance, shipment, job, an item, etc. Whenever a state is changed for an item or any operation, an alert will be generated to you, your employees, consignee, or any other required set of people as SMS and emails. You will know whether a task assigned is started, partially completed, or completed.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("Inventorymodule")} id="Inventorymodule"
                    className="alingupside"
                  >

                    <div className="sample" id="sample20" onClick={() => focusMethods("Inventorymodule")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Direct Shipments and</Trans>
                        <br /><Trans>Consolidation (LTL and FTL)</Trans>
                      </div>
                      {id === "Inventorymodule" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Inventory Module.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Direct Shipments and</Trans>
                              <br /><Trans>Consolidation (LTL and FTL)</Trans>
                            </div>
                          </div>

                          <div className="Inventory_first_maincontent" lang={context.language}>
                            <Trans>Logipulse provides you the facility to optimize direct shipments (FTL) and consolidation (LTL) within a short duration, by utilizing the space effectively and cost-efficiently with the technology of advanced algorithms. You can include as many houses as possible in consolidated shipments and generate bills for that easily.</Trans>
                            
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("IntegratedFinance")} id="IntegratedFinance"
                    className="alingupside"
                  >

                    <div className="sample" id="sample21" onClick={() => focusMethods("IntegratedFinance")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Integrated Finance Module</Trans></div>
                      {id === "IntegratedFinance" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Container Tracking.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Integrated Finance Module</Trans>
                            </div>
                          </div>

                          <div className="Trackings_first_maincontent" lang={context.language}>
                            <Trans>Against all the actions of the operation module, journal entries will be triggered in the finance module reflecting all the financial transactions. This feature will help your accounting person handle customers easily. Payments may be done partially or fully sometimes at origin or destination, so all these complicated accounting transactions can be generated from the operation without the need for journal entries.</Trans>
                            <br></br><br></br>
                            <Trans>Among the detailed features, a few important ones are cost center, general ledger report, batch invoicing, Proforma DA, financial period summary, profit and loss reports, income statements of shipments and jobs, asset or liability balance sheets, aging report, payment report, outstanding report, tax summaries, sundry debtors and creditors, actor specific ledger report, consolidation accounting report, reports of multiple branches and agencies, manifest report, virtual ledger, etc.</Trans>
                            <br></br><br></br>
                            <Trans>While maintaining a specific ledger for every walk-in customer, the chart of accounts will get bulged. So instead of that Logipulse helps the system to act as a ledger automatically with its virtual ledger feature.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("CRM")} id="CRM"
                    className="alingupside"
                  >

                    <div className="sample" id="sample21" onClick={() => focusMethods("CRM")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Well Defined CRM Module</Trans></div>
                      {id === "CRM" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Container Tracking.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Well Defined CRM Module</Trans>
                            </div>
                          </div>

                          <div className="Trackings_first_maincontent" lang={context.language}>
                            <Trans>You can build up your customer circle using the facilities provided in the Logipulse CRM module. You will have better compatibility with your customer. It will be easier for you to follow up enquiries and quotes. You can study how you achieved a new customer and a lot more from this section. Thus, you will be able to elaborate your customer list.</Trans>
                            <br></br><br></br>
                            <Trans>From lead to quote and from quotation to shipment/pickup, a one-click is only required to move further. You can easily email your customers. The supply chain has a high demand for customer interaction. Beginning from the quotation, pickup, storage, and distribution, it would be more convenient to communicate with your customer.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>




                <div className="materialssection">
                  <div
                    onClick={() => operations("CustomerPortal")} id="CustomerPortal"
                    className="alingupside"
                  >

                    <div className="sample" id="sample23" onClick={() => focusMethods("CustomerPortal")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Customer Portal & Tracking</Trans></div>

                      {id === "CustomerPortal" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Sarehouse 2D View.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Customer Portal & Tracking</Trans>
                            </div>
                          </div>

                          <div className="Warehouse_first1_maincontent" lang={context.language}>
                            <Trans>You can provide your customers access to track their shipments using Waybill or shipment numbers through the customer portal. Using access details your customers can log in to the portal and see the details of the shipments, for instance, the item details, quantity, etc.</Trans>
                            <br></br><br></br>
                            <Trans>The customer portal implements end-to-end visibility to your customers for their freight orders. Right from placing orders customers can observe their logistic partner operations in real-time. Customers can watch the status of their orders, track their cargo during transportation, and also upload or download important shipping records if they need them.</Trans>

                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("Proforma")} id="Proforma"
                    className="alingupside"
                  >

                    <div className="sample" id="sample24" onClick={() => focusMethods("Proforma")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>
                        <Trans>Inventory Module</Trans></div>

                      {id === "Proforma" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="Perfoma Disbursement.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Inventory Module</Trans>
                            </div>
                          </div>

                          <div className="ProForma_first_maincontents" lang={context.language}>
                            <Trans>In-house inventory and customers inventory management are enabled in the app with seamless integration. You can create an inventory list, purchase request (internal stock filling request facility from warehouse owner to supplier), a purchase order (details of the purchasing), purchase receipt, and automatic invoice and bill generation are also possible. Reports, purchase return (raise a return of damaged goods received), stock issue (selling or shipping inventory items from the warehouse), issued history (full log of issued stocks), stock return (the issued item returned shows the returned stocks), all the above-mentioned operations are integrated with the accounting module so that every financial activity will be automatically reflected in the accounts whether it is a sale or a return.</Trans>
                          </div>

                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="materialssection">
                  <div
                    onClick={() => operations("QRcodes")} id="QRcodes"
                    className="alingupside"
                  >

                    <div className="sample" id="sample25" onClick={() => focusMethods("QRcodes")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>

                        <Trans>Barcodes and QR codes</Trans></div>
                      {id === "QRcodes" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="PDA Device.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Barcodes and QR codes</Trans>
                            </div>
                          </div>

                          <div className="PDA_first_maincontents" lang={context.language}>
                            <Trans>Generate and print barcodes of each shipment easily using the PDA device and application. To make the pickup, delivery, and warehousing processes fast, easy and reliable, barcodes play a vital role. Barcode printing and scanning avoids human errors and makes inventory handling effortless. It takes only minutes to understand and familiarize the hand-held scanner for reading barcodes, thus making employee training easier. Above all, as barcodes can be attached to any surface, they can be used to track and trace the products, outgoing shipments, etc.</Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>


                <div className="materialssection">
                  <div
                    onClick={() => operations("2DView")} id="2DView"
                    className="alingupside"
                  >

                    <div className="sample" id="sample25" onClick={() => focusMethods("2DView")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>

                        <Trans>Warehouse 2D View</Trans></div>
                      {id === "2DView" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="PDA Device.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>Warehouse 2D View</Trans>
                            </div>
                          </div>

                          <div className="PDA_first_maincontents" lang={context.language}>
                            <Trans>Items, inventory, or containers placed in the warehouse can be seen in two dimensions placed in the bins. Free spaces, number of racks, bins, etc. can also be seen from the 2D view feature of Logipulse application. Every warehouse will have different racking structures, which can be defined accordingly. Any kind of racking system can be configured in the Logipulse warehousing app. Racks and bins can be name customized as per the users requirements. Total space, free space, etc. will be automatically calculated by the system. When a new item arrives, the automated system allocates a free space correctly.

                            </Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>


                <div className="materialssection">
                  <div
                    onClick={() => operations("PDAdevice")} id="PDAdevice"
                    className="alingupside"
                  >

                    <div className="sample" id="sample25" onClick={() => focusMethods("PDAdevice")}>

                      <div className="spacecontent">
                        <div className="rightup" lang={context.language}>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="testing"
                            alt=""
                          />
                        </div>

                        <Trans>PDA Device and Application</Trans></div>
                      {id === "PDAdevice" && isToggle === true ? (
                        <div className="row scroll-to-top">

                          <div className="contents" lang={context.language}>
                            <div className="Image">
                              <Photo
                                src="PDA Device.jpg"

                                alt=""
                              />
                            </div>
                            <div><Photo src="verified_black_24dp.svg" alt="" className="ico_features" /></div>
                            <div className="main_content" id="id0">
                              {" "}
                              <Trans>PDA Device and Application</Trans>
                            </div>
                          </div>

                          <div className="PDA_first_maincontents" lang={context.language}>
                            <Trans>The PDA device is a handheld device with the Logipulse application integrated with all the operation management tools specifically for your warehouse processes. The device is enabled to create issues, receipt, put away and picklist, bin allocations, etc. Proof of delivery can be signed on the device. Product barcode scanning (Stock keeping unit SKU) will be easier with the Logipulse PDA device. Every item inbound and outbound will be scanned making further actions trackable. The device comes with a flexible and most user-friendly interface, which even a new employee can learn with little training.

                            </Trans>
                          </div>


                          <br></br>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="features_mb d-block d-md-none">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Photo
                src="Bottom Image-11.svg"
                className="innerimage"
                alt="logipulse-background-images"
              />
            </div>

          </div>
        </div>
      </div>



    </div>

  )
}
export default Features_mbl
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`